import { z } from 'zod';

export const UserStoreSchema = z
  .object({
    'electron-pinned': z
      .string()
      .transform((arg, ctx): string[] => {
        try {
          // the store cannot store arrays, so encoding/decoding is needed
          const parsed = JSON.parse(arg);
          if (!Array.isArray(parsed)) {
            throw Error('Invalid pinned data');
          }
          return parsed;
        } catch (e) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: (e as any)?.message ?? 'Invalid pinned data',
          });
          return z.NEVER;
        }
      })
      .catch(() => {
        return [];
      }),
  })
  .catch({
    'electron-pinned': [],
  });

export type UserStore = z.infer<typeof UserStoreSchema>;

export const UserStoreResponseSchema = z
  .object({
    records: z.array(
      z.object({
        k: z.string(),
        v: z.string().nullish(),
        created_at: z.string(),
      })
    ),
    size: z.number(),
  })
  .transform((parsed) => {
    const pinned = parsed.records.find((r) => r.k === 'electron-pinned');
    return UserStoreSchema.parse({
      'electron-pinned': pinned?.v,
    });
  });

export type UserStoreResponse = z.infer<typeof UserStoreResponseSchema>;
