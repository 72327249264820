/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';
import styled from './Spinner.module.scss';

export type SpinnerProps = ComponentProps<'div'>;

/**
 * @version 1.4.0
 * @since 2.0.0-beta.85
 */
export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  ({ className, color = 'info', ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={clsx(styled.root, className)}
        data-chromatic="ignore"
      />
    );
  }
);
