import { z } from 'zod';

export const SortBy = z.enum([
  'duration',
  'createdAt',
  'title',
  'artist',
  'album',
  'none',
]);

export const SortOrd = z.enum(['asc', 'desc']);

// note: cannot be used in the schema because is a circular reference to type
export const defaultOrder: FileListSort = {
  sortBy: 'createdAt',
  sortOrd: 'asc',
};

export const defaultPlaylistOrder: FileListSort = {
  sortBy: 'none',
  sortOrd: 'desc',
};

export const FileListSortSchema = z
  .object({
    sortBy: SortBy.default('createdAt'),
    sortOrd: SortOrd.default('desc'),
  })
  .default({
    sortBy: 'createdAt',
    sortOrd: 'desc',
  });

export type FileListSort = z.infer<typeof FileListSortSchema>;
