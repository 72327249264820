import { z } from 'zod';
import { Song } from '../../../../common/Song';
import { ObjectToUnion } from '../../../utils/types-utils';

export const UpdateFileFormSchema = z
  .object({
    metadata_album: z.string(),
    metadata_artist: z.string(),
    metadata_bpm: z.string(),
    metadata_copyright_date: z.string(),
    metadata_distributor: z.string(),
    metadata_explicit: z.string(),
    metadata_genre: z.string(),
    metadata_isrc: z.string(),
    metadata_iswc: z.string(),
    metadata_key: z.string(),
    metadata_language: z.string(),
    metadata_license: z.string(),
    metadata_master_owner: z.string(),
    metadata_pline: z.string(),
    metadata_publisher: z.string(),
    metadata_release_date: z.string(),
    metadata_track_count: z.string(),
    metadata_track_number: z.string(),
    metadata_upc_barcode: z.string(),
    metadata_year: z.string(),
    title: z.string(),
  })
  .strict();

export type UpdateFileForm = z.infer<typeof UpdateFileFormSchema>;

export type UpdateFileFormField = ObjectToUnion<UpdateFileForm>;

export function updateFileInPlace(
  update: UpdateFileFormField,
  song: Song
): Song {
  if ('title' in update) {
    song.title = update.title;
  }

  if ('metadata_album' in update) {
    song.metadataAlbum = update.metadata_album;
  }

  if ('metadata_artist' in update) {
    song.metadataArtist = `${update.metadata_artist}`;
  }

  if ('metadata_bpm' in update) {
    song.metadataBpm = Number(update.metadata_bpm);
  }

  if ('metadata_copyright_date' in update) {
    song.metadata.metadataCopyrightDate = update.metadata_copyright_date;
  }

  if ('metadata_distributor' in update) {
    song.metadata.metadataDistributor = update.metadata_distributor;
  }

  if ('metadata_explicit' in update) {
    song.metadata.metadataExplicit = update.metadata_explicit;
  }

  if ('metadata_genre' in update) {
    song.metadataGenre = update.metadata_genre;
  }

  if ('metadata_isrc' in update) {
    song.metadata.metadataIsrc = update.metadata_isrc;
  }

  if ('metadata_iswc' in update) {
    song.metadata.metadataIswc = update.metadata_iswc;
  }

  if ('metadata_key' in update) {
    song.metadata.metadataKey = update.metadata_key;
  }

  if ('metadata_language' in update) {
    song.metadata.metadataLanguage = update.metadata_language;
  }

  if ('metadata_license' in update) {
    song.metadata.metadataLicense = update.metadata_license;
  }

  if ('metadata_master_owner' in update) {
    song.metadata.metadataMasterOwner = update.metadata_master_owner;
  }

  if ('metadata_pline' in update) {
    song.metadata.metadataPline = update.metadata_pline;
  }

  if ('metadata_publisher' in update) {
    song.metadata.metadataPublisher = update.metadata_publisher;
  }

  if ('metadata_release_date' in update) {
    song.metadata.metadataReleaseDate = update.metadata_release_date;
  }

  if ('metadata_track_count' in update) {
    song.metadataTrackCount = Number(update.metadata_track_count);
  }

  if ('metadata_track_number' in update) {
    song.metadataTrackNumber = Number(update.metadata_track_number);
  }

  if ('metadata_upc_barcode' in update) {
    song.metadata.metadataUpcBarcode = update.metadata_upc_barcode;
  }

  if ('metadata_year' in update) {
    song.metadataYear = Number(update.metadata_year);
  }

  if ('title' in update) {
    song.title = update.title;
  }

  return song;
}
