import { z } from 'zod';
import { ObjectToUnion } from '../../../utils/types-utils';
import { Workspace } from './Workspace';

export const UpdateWorkspaceDetailsFormSchema = z
  .object({
    title: z.string(),
  })
  .strict();

export type UpdateWorkspaceDetailsForm = z.infer<
  typeof UpdateWorkspaceDetailsFormSchema
>;

export type UpdateWorkspaceDetailsFormField =
  ObjectToUnion<UpdateWorkspaceDetailsForm>;

export const UpdateWorkspaceDescriptionFormSchema = z
  .object({
    description: z.string(),
  })
  .strict();

export type UpdateWorkspaceDescriptionForm = z.infer<
  typeof UpdateWorkspaceDescriptionFormSchema
>;

export type UpdateWorkspaceDescriptionFormField =
  ObjectToUnion<UpdateWorkspaceDescriptionForm>;

export function updateWorkspaceInPlace(
  update: UpdateWorkspaceDetailsFormField | UpdateWorkspaceDescriptionFormField,
  workspace: Workspace
): Workspace {
  if ('title' in update) {
    workspace.title = update.title;
  }

  if ('description' in update) {
    workspace.description = update.description;
  }

  return workspace;
}
