/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';
import { Spinner } from '../../data-display/Icons/Spinner/Spinner';
import styled from './Button.module.scss';

// TODO: "icon" variant should superpeed size maybe? to not repeat
export type ButtonProps = ComponentProps<'button'> & {
  variant:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'alert'
    | 'underline'
    | 'tabs'
    | 'icon';
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'icon';
  isLoading?: Boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, isLoading, children, size = 'M', ...props }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        {...props}
        className={clsx(
          styled[`size-${size}`],
          styled[`variant-${variant}`],
          isLoading && styled.isLoading,
          props.className
        )}
      >
        {isLoading ? <Spinner /> : children}
      </button>
    );
  }
);
