import { z } from 'zod';

export const FeatureSchema = z
  .enum([
    'COLLABORATIVE_PLAYLIST',
    'REPLACE_FILE',
    'SHARE_LINK',
    'SHARE_LINK_WITH_DOWNLOADS',
    'SHARE_LINK_WITH_EXPIRATION',
    'SHARE_LINK_WITH_PASSWORD',
    'TIMESTAMPED_COMMENT',
  ])
  .catch(
    (ctx) =>
      // prevent failing when new permissions are added
      ctx.input
  );

export type Feature = z.infer<typeof FeatureSchema>;
