import { z } from 'zod';
import {
  WorkspaceIdSchema,
  WorkspaceInvitationIdSchema,
} from '../../../../common/Opaques';
import { fromNullish } from '../../../../common/zod-utils';

// TODO: change properties snake_case to camelCase
export const WorkspaceRoleSchema = z.union([
  z.literal('viewer'),
  z.literal('owner'),
  z.literal('editor'),
  z.literal('guest'),
  z.literal('admin'),
]);

export type WorkspaceRole = z.infer<typeof WorkspaceRoleSchema>;

export const WorkspaceMemberUserUserSchema = z
  .object({
    artist_name: z.string().nullable().transform(fromNullish('')),
    artwork_url: z.string().nullable(),
    avatar_url: z.string().nullable(),
    created_at: z.string().transform((date) => new Date(date)),
    email: z.string(),
    is_workspace_owner: z.boolean().optional(),
    location: z.string().nullable(),
    name: z.string().nullable().transform(fromNullish('')),
    occupation: z.string().nullable(),
    steps_complete: z.number().nullable(),
    user_id: z.string(),
  })
  .transform((prev) => ({
    artistName: prev.artist_name,
    artworkUrl: prev.artwork_url,
    avatarUrl: prev.avatar_url,
    createdAt: prev.created_at,
    email: prev.email,
    isWorkspace_owner: prev.is_workspace_owner,
    location: prev.location,
    name: prev.name,
    occupation: prev.occupation,
    stepsComplete: prev.steps_complete,
    userId: prev.user_id,
  }));

export const WorkspaceMemberSchema = z
  .object({
    member_since: z.string(),
    user: WorkspaceMemberUserUserSchema,
    workspace_id: WorkspaceIdSchema,
    workspace_role: WorkspaceRoleSchema,
  })
  .transform((prev) => ({
    memberSince: prev.member_since,
    user: prev.user,
    workspaceId: prev.workspace_id,
    workspaceRole: prev.workspace_role,
  }));

export type WorkspaceMember = z.infer<typeof WorkspaceMemberSchema>;

export const WorkspaceInvitationSchema = z
  .object({
    created_at: z.string().transform((date) => new Date(date)),
    workspace_id: WorkspaceIdSchema,
    invitation_id: WorkspaceInvitationIdSchema,
    role: WorkspaceRoleSchema,
    email: z.string(),
  })
  .transform((prev) => ({
    createdAt: prev.created_at,
    workspaceId: prev.workspace_id,
    invitationId: prev.invitation_id,
    role: prev.role,
    email: prev.email,
  }));

export type WorkspaceInvitation = z.infer<typeof WorkspaceInvitationSchema>;

export const defaultWorkspaceMember: WorkspaceMember = {
  workspaceId: WorkspaceIdSchema.parse('s_vxwaEOn'),
  memberSince: '2024-01-13T14:11:40.000Z',
  workspaceRole: 'editor',
  user: {
    artistName: 'artistName',
    artworkUrl: 'https://i.pravatar.cc/150?img=1',
    avatarUrl: 'https://i.pravatar.cc/150?img=1',
    createdAt: new Date('2024-01-13T14:11:40.000Z'),
    email: 'email',
    isWorkspace_owner: false,
    location: 'location',
    name: 'name',
    occupation: 'occupation',
    stepsComplete: 1,
    userId: 'userId',
  },
};
