import { z } from 'zod';
import { transformToDate } from '../../common/zod-utils';

export const ShareLinkSchema = z
  .object({
    artworkUrl: z.string().nullish(),
    content_object_id: z.string(),
    created_at: z.string().transform(transformToDate),
    disabled_download: z.union([z.literal(0), z.literal(1)]).transform(Boolean),
    expires_at: z.string().transform(transformToDate).nullable(),
    password: z.string().nullable(),
    share_id: z.string(),
    share_url: z.string(),
    title: z.string(),
    type: z.union([z.literal('playlist'), z.literal('audio')]),
  })
  .transform((prev) => ({
    artworkUrl: prev.artworkUrl,
    contentObjectId: prev.content_object_id,
    createdAt: prev.created_at,
    disabledDownload: prev.disabled_download,
    expiresAt: prev.expires_at,
    password: prev.password,
    shareId: prev.share_id,
    shareUrl: prev.share_url,
    title: prev.title,
    type: prev.type,
  }));

export type ShareLink = z.infer<typeof ShareLinkSchema>;

export const defaultShareLink: ShareLink = {
  artworkUrl: null,
  contentObjectId: '',
  createdAt: new Date('2021-01-01T00:00:00.000Z'),
  disabledDownload: true,
  expiresAt: null,
  password: null,
  shareId: '',
  shareUrl: 'https://vollume.com/s/p/6hjk_yioe%',
  title: 'Amazing New Music',
  type: 'playlist',
};
