import { copy, lookup, prepend, unsafeDeleteAt } from 'fp-ts/lib/Array';
import { flow, pipe } from 'fp-ts/lib/function';
import { flatMap, Option, toUndefined } from 'fp-ts/lib/Option';

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function randomSortArray<T>(array: T[]) {
  return [...array].sort(() => Math.random() - 0.5);
}

/**
 *
 * Creates an array of unique values, in order from both arrays
 * @param a first array
 * @param b second array
 * @param by generate the criterion by which uniqueness is computed
 * @param mapA function to map each element when only exits in A array
 * @param mapB function to map each element when only exits in B array
 * @param mapAB function to map when elements that exist in both A and B
 */
export function unionByFilterMapWiden<T0, T1, T2, T3, T4>(
  a: T0[],
  b: T1[],
  by: (t: T0 | T1) => string,
  mapA: (a: T0) => T2 | undefined,
  mapAB: (a: T0, b: T1) => T4 | undefined,
  mapB: (b: T1) => T3 | undefined
): (T2 | T3 | T4)[] {
  const toReturn: (T2 | T3 | T4)[] = [];
  const indexed = a.reduce<Record<string, T0>>((acc, item) => {
    acc[by(item)] = item;
    return acc;
  }, {});

  b.forEach((item) => {
    const id = by(item);
    const existingItem = indexed[id];
    const mapped = existingItem ? mapAB(existingItem, item) : mapB(item);
    if (mapped) {
      toReturn.push(mapped);
    }
    delete indexed[id];
  });

  return Object.values(indexed)
    .flatMap<T2 | T3 | T4>((item) => {
      const mapped = mapA(item);
      return mapped ? [mapped] : [];
    })
    .concat(toReturn);
}

export function sequentialPick<T>(index: number, pickFrom: T[]) {
  return pickFrom[index % pickFrom.length];
}

/**
 * Shuffles all songs in the queue and moves the currently playing song to the beginning.
 */
export function shuffleQueue<T>(queue: T[], item?: T): T[] {
  const shuffledQueue = randomSortArray(queue);
  if (item) {
    const itemIndex = shuffledQueue.indexOf(item);
    shuffledQueue.splice(itemIndex, 1);
    shuffledQueue.unshift(item);
  }
  return shuffledQueue;
}

/**
 * Shuffles all songs in the queue and moves the currently playing song to the beginning.
 */
export function shuffleExcept<T>(queue: T[], itemIndex: number): T[] {
  const cQueue = copy(queue);
  const item = cQueue[itemIndex];
  if (!item) {
    throw new Error('Item not found');
  }
  const shuffledQueue = randomSortArray(unsafeDeleteAt(itemIndex, cQueue));
  return prepend<T>(item)(shuffledQueue);
}

export function lookupInOption<T>(
  array: T[],
  index: Option<number>
): Option<T> {
  return pipe(
    index,
    flatMap((x) => lookup(x, array))
  );
}

export const lookupOrUndefined = flow(lookupInOption, toUndefined);

export function isIntersectedBy<A, B>(
  a: A[],
  b: B[],
  predicate: (a: A, b: B) => boolean
): boolean {
  return a.some((item) => b.some((item2) => predicate(item, item2)));
}

/**
 * @description Inserts a separator between each section only not empty
 */
export function flatIntersperse<T>(separator: T, array: T[][]): T[] {
  return array.reduce((acc, item, index) => {
    if (index === 0) {
      return item;
    }
    if (item.length === 0) {
      return acc;
    }
    if (acc.length === 0) {
      return acc.concat(item);
    }
    return acc.concat([separator], item);
  }, []);
}
