// import * as Sentry from '@sentry/browser';
import { z } from 'zod';

export function reportEverywhere(code: string) {
  return (e: unknown) => {
    // eslint-disable-next-line no-console
    console.error(code, e);
    // Sentry.captureException(e, {
    //   tags: {
    //     code,
    //   },
    // });
    window.nativeAPI.log.error(code, e);
  };
}

export async function tryCatchFinallyReport<T>(
  code: string,
  fn0: () => Promise<T>,
  onError?: (e: unknown) => void
): Promise<T> {
  try {
    return await fn0();
  } catch (e) {
    reportEverywhere(code)(e);
    onError?.(e);
    throw e;
  }
}

const HttpErrorSchema = z.object({
  data: z.object({
    message: z.string().min(1),
  }),
});

export function getErrorMessageOrElse(
  error: unknown,
  defaultMessage: string
): string {
  const getHttpError = HttpErrorSchema.safeParse(error);

  if (getHttpError.success) {
    return getHttpError.data.data.message;
  }

  return defaultMessage;
}
