/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';
import { getColorById } from '../../../utils/color-utils';
import styles from './Squircle.module.scss';

export interface SquircleProps extends ComponentProps<'div'> {
  char: string;
  id: string;
  imageUrl: string | null | undefined;
  size: 'X' | 'M' | 'S';
}

export const Squircle = forwardRef<HTMLDivElement, SquircleProps>(
  function Squircle(
    { id, imageUrl, char, size, className, style, ...props },
    ref
  ) {
    return (
      <div
        ref={ref}
        className={clsx(styles.artwork, styles[`size-${size}`], className)}
        style={{
          ...(imageUrl
            ? {
                backgroundImage: `url(${imageUrl})`,
              }
            : {
                backgroundColor: `#${getColorById(id)}`,
              }),
          ...style,
        }}
        {...props}
      >
        {imageUrl ? null : char}
      </div>
    );
  }
);
