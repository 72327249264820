import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Home } from './Home';
import { store } from './api';
import './global.scss';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  const shareId = window.location.href.split('/').at(3)!;
  root.render(
    <Provider store={store}>
      <Home shareId={shareId} />
    </Provider>
  );
});
