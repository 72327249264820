/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */

const PLACEHOLDER_COLORS = [
  'C9B859',
  'B09216',
  '986212',
  'D560B1',
  'B0144E',
  'B0141F',
  '54A664',
  '13B039',
  '0C6318',
  '65499D',
  '8A16B0',
  '4815B1',
  '16A2B0',
  '1668B0',
  '2B15B0',
];

/**
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 *
 * The hash code for a string object is computed as
 *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
 * using number arithmetic, where s[i] is the i th character
 * of the given string, n is the length of the string,
 * and ^ indicates exponentiation.
 * (The hash value of the empty string is zero.)
 *
 * @param {string} s a string
 * @return {number} a hash code value for the given string.
 */
function hashCode(s: string): number {
  let h = 0;
  const l = s.length;
  let i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
}

export function getColorById(id: string, pickFrom = PLACEHOLDER_COLORS) {
  const hash = Math.abs(hashCode(id));
  const index = hash % pickFrom.length;
  const color = pickFrom[index];
  return color;
}

export const PROJECT_COLORS = [
  'FDFFA8',
  'FFA8FC',
  'A8FFEF',
  'FFA8A8',
  'C4FFA8',
  'FFF6A8',
  'A8E0FF',
  'EEA8FF',
  'A8FFB6',
  'FFDCA8',
  'D9FFA8',
  'A8CCFF',
];
