import { z } from 'zod';
import { Playlist } from '../../../types/Playlist';
import { ObjectToUnion } from '../../../utils/types-utils';

export const UpdatePlaylistDetailsFormSchema = z
  .object({
    title: z.string(),
  })
  .strict();

export type UpdatePlaylistDetailsForm = z.infer<
  typeof UpdatePlaylistDetailsFormSchema
>;

export type UpdatePlaylistDetailsFormField =
  ObjectToUnion<UpdatePlaylistDetailsForm>;

export const UpdatePlaylistDescriptionFormSchema = z
  .object({
    description: z.string(),
  })
  .strict();

export type UpdatePlaylistDescriptionForm = z.infer<
  typeof UpdatePlaylistDescriptionFormSchema
>;

export type UpdatePlaylistDescriptionFormField =
  ObjectToUnion<UpdatePlaylistDescriptionForm>;

export function updatePlaylistInPlace(
  update: UpdatePlaylistDetailsFormField | UpdatePlaylistDescriptionFormField,
  playlist: Playlist
): Playlist {
  if ('title' in update) {
    playlist.title = update.title;
  }

  if ('description' in update) {
    playlist.description = update.description;
  }

  return playlist;
}
