import volumeIcon from '../../assets/images/shareonweb/vollume_icon.svg';
import styles from './Password.module.scss';

export interface ErrorPageProps {
  status: number;
}

export function ErrorPage({ status }: ErrorPageProps) {
  return (
    <div className={styles.root}>
      <img alt="Vollume Inc." height={60} src={volumeIcon} width={60} />
      <h1>{`Error (${status})`}</h1>
      <h2>We can’t find the page you’re looking for.</h2>
      <h3>
        The link you followed may be broken or has been removed by the owner.
      </h3>
      <h3>Here are a few links you may find helpful:</h3>
      <a href="https://account.vollume.com/">Get A Free Account</a>
      <a href="https://vollume.com/">Learn More About Vollume</a>
      <a href="https://www.vollume.com/plans">Plans & Pricing</a>
    </div>
  );
}
