/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import VOLUME_TICK from '../constants/player';
import styles from './SeekBar.module.scss';

type Props = {
  value: number;
  max: number;
  onSeeking?: (value: number) => void;
  onSeekComplete?: (value: number) => void;
  getTooltipValue?: (value: number, max: number) => string;
  className?: string;
  seekOnMouseWheel?: boolean;
  stickyPrimaryBar?: boolean;
  showTooltip?: boolean;
  disabled?: boolean;
  animate?: boolean;
  onTooltipClick?: (progress: number) => void;
};

function normalizeToOne(value: number, max: number) {
  return Math.max(0, Math.min(1, value / max));
}

export default function SeekBar({
  value,
  max,
  onSeeking,
  onSeekComplete,
  getTooltipValue,
  className,
  onTooltipClick,
  seekOnMouseWheel = false,
  stickyPrimaryBar = false,
  showTooltip = false,
  disabled = false,
  animate = false,
}: Props) {
  const container = useRef<HTMLDivElement>(null);
  const hitBoxRef = useRef<HTMLDivElement>(null);
  const [secondaryProgress, setSecondaryProgress] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState(0);
  const [tooltipValue, setTooltipValue] = useState(0);
  const [primaryProgress, setPrimaryProgress] = useState(
    normalizeToOne(value, max)
  );
  const mouseMoved = useRef(false);

  useEffect(() => {
    if (!mouseMoved.current) {
      setPrimaryProgress(normalizeToOne(value, max));
    }
  }, [value, max]);

  // const onMouseMove = useCallback(
  //   (event: React.MouseEvent) => {
  //     const containerEl = container.current;
  //     if (!disabled && containerEl && !mouseMoved.current) {
  //       const containerWidth = containerEl.offsetWidth;
  //       const containerRect = containerEl.getBoundingClientRect();
  //       const cursorX = event.clientX;
  //       const progress = (cursorX - containerRect.left) / containerRect.width;

  //       const hitBox = hitBoxRef.current?.getBoundingClientRect();
  //       const isInHitBox =
  //         hitBox &&
  //         hitBox.left <= event.clientX &&
  //         event.clientX <= hitBox.right &&
  //         hitBox.top <= event.clientY + (tooltipPosition === -1 ? 0 : 20) &&
  //         hitBox.bottom >= event.clientY;

  //       if (showTooltip && isInHitBox) {
  //         if (hitBox && hitBox.top <= event.clientY) {
  //           const tooltipPadding = 15;
  //           const tooltipWidth = 70;
  //           const clippedTooltipPosition = Math.min(
  //             Math.max(tooltipPadding, event.clientX - tooltipWidth / 2),
  //             containerWidth - 2 * tooltipPadding - tooltipWidth
  //           );
  //           setTooltipPosition(clippedTooltipPosition);

  //           setTooltipValue(progress);
  //         }
  //       } else {
  //         setTooltipPosition(-1);
  //       }

  //       setSecondaryProgress(
  //         normalizeToOne(
  //           event.clientX - containerRect.left,
  //           containerRect.width
  //         )
  //       );
  //     }
  //   },
  //   [disabled, showTooltip, tooltipPosition]
  // );

  const onMouseMove = useCallback(
    (event: React.MouseEvent) => {
      const containerEl = container.current;
      if (!disabled && containerEl && !mouseMoved.current) {
        const containerRect = containerEl.getBoundingClientRect();
        const cursorX = event.clientX;
        const progress = (cursorX - containerRect.left) / containerRect.width;
        if (showTooltip) {
          // BUG: cannot click on tooltip
          setTooltipPosition(event.clientX - containerRect.left);
          setTooltipValue(progress);
        } else {
          setTooltipPosition(-1);
        }

        setSecondaryProgress(
          normalizeToOne(
            event.clientX - containerRect.left,
            containerRect.width
          )
        );
      }
    },
    [disabled, showTooltip]
  );

  const onMouseDownHandler = (
    mouseDownEvent: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const containerRect = container.current?.getBoundingClientRect();
    function stickyMouse(event: MouseEvent) {
      if (containerRect) {
        const clippedProgress = normalizeToOne(
          event.clientX - containerRect.left,
          containerRect.width
        );

        setPrimaryProgress(() => {
          setTimeout(() => {
            mouseMoved.current = true;
            onSeeking?.(clippedProgress * max);
          });
          return clippedProgress;
        });
        setSecondaryProgress(0);
      }
    }

    function toolTip(event: MouseEvent) {
      if (containerRect) {
        const progress = normalizeToOne(
          event.clientX - containerRect.left,
          containerRect.width
        );

        if (showTooltip) {
          const tooltipPadding = 15;
          const tooltipWidth = 70;
          const clippedTooltipPosition = Math.min(
            Math.max(tooltipPadding, event.clientX - tooltipWidth / 2),
            containerRect.width - 2 * tooltipPadding - tooltipWidth
          );
          setTooltipPosition(clippedTooltipPosition);

          setTooltipValue(progress);
        } else {
          setTooltipPosition(-1);
        }
      }
    }

    if (!mouseMoved.current && !disabled) {
      if (stickyPrimaryBar) {
        stickyMouse(mouseDownEvent.nativeEvent);
        window.addEventListener('mousemove', stickyMouse);
      }

      if (showTooltip) {
        window.addEventListener('mousemove', toolTip);
      }

      window.addEventListener(
        'mouseup',
        () => {
          window.removeEventListener('mousemove', stickyMouse);
          window.removeEventListener('mousemove', toolTip);
          setTooltipPosition(-1);

          if (stickyPrimaryBar && mouseMoved.current) {
            setPrimaryProgress((prev) => {
              setTimeout(() => {
                onSeekComplete?.(prev * max);
              });
              return prev;
            });
          }
          setTimeout(() => {
            mouseMoved.current = false; // small buffer to wait for other onSeekComplete reaction propagation to value
          }, 100);
        },
        {
          once: true,
        }
      );
    }
  };

  const onMouseLeave = () => {
    if (!mouseMoved.current) {
      setTooltipPosition(-1);
      setSecondaryProgress(0);
    }
  };

  const onMouseWheel = useCallback(
    (event: React.WheelEvent) => {
      if (seekOnMouseWheel) {
        if (event.deltaY > 0) {
          setPrimaryProgress((prev) => {
            const nextValue = Math.max(0, prev - VOLUME_TICK);
            setTimeout(() => {
              onSeekComplete?.(nextValue * max);
            });
            return nextValue;
          });
        } else {
          setPrimaryProgress((prev) => {
            const nextValue = Math.min(1, prev + VOLUME_TICK);
            setTimeout(() => {
              onSeekComplete?.(nextValue * max);
            });
            return nextValue;
          });
        }
      }
    },
    [max, onSeekComplete, seekOnMouseWheel]
  );

  const onClick = (e: React.MouseEvent) => {
    if (!disabled) {
      const containerEl = container.current;
      const hitBox = hitBoxRef.current?.getBoundingClientRect();
      if (containerEl && hitBox && hitBox.top <= e.clientY) {
        // Cursor position relative to container
        const rect = containerEl.getBoundingClientRect();
        const progress = Math.max(
          0,
          Math.min(1, (e.clientX - rect.left) / rect.width)
        );
        setPrimaryProgress(() => {
          setTimeout(() => {
            onSeekComplete?.(progress * max);
          });
          return progress;
        });
      }
    }
  };

  return (
    <div
      ref={container}
      className={`${styles.container} ${className}`}
      data-tid="container"
      onClick={onClick}
      onMouseDown={onMouseDownHandler}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onWheel={onMouseWheel}
    >
      <div ref={hitBoxRef} className={styles.hitbox} />
      <div
        className={styles.tooltip}
        style={{
          left: `${tooltipPosition}px`,
          display: tooltipPosition > 0 && tooltipValue ? 'flex' : 'none',
          // position: 'fixed',
        }}
      >
        <div className={styles.tooltipProgress}>
          {getTooltipValue ? getTooltipValue(tooltipValue, max) : ''}
        </div>
        <div
          className={styles.tooltipPlusButton}
          onClick={() => {
            onTooltipClick?.(tooltipValue * max);
          }}
        >
          +
        </div>
      </div>
      <div
        className={styles.secondaryProgress}
        style={{ width: `${secondaryProgress * 100}%` }}
      />
      <div
        className={clsx(
          styles.progress,
          animate && !mouseMoved.current && styles.anim
        )}
        data-testid="progress-bar"
        style={{
          width: `${disabled ? 0 : primaryProgress * 100}%`,
        }}
      />
    </div>
  );
}
