import { z } from 'zod';

export const PermissionSchema = z
  .enum([
    'administrator',
    'create_projects',
    'create_share_links',
    'delete_comments',
    'delete_playlists',
    'delete_projects',
    'delete_share_links',
    'delete_songs',
    'delete_workspace',
    'edit_projects_details',
    'edit_workspace_details',
    'invite_member',
    'manage_attachments',
    'manage_billing',
    'manage_playlists',
    'manage_recently_deleted',
    'manage_roles',
    'manage_share_links',
    'manage_songs',
    'remove_member',
    'send_comments',
    'view_all_workspace_songs',
    'view_attachments',
    'view_comments',
    'view_member_list',
    'view_resource',
    'view_share_links',
  ])
  .catch(
    (ctx) =>
      // prevent failing when new permissions are added
      ctx.input
  );

export type Permission = z.infer<typeof PermissionSchema>;
