/* eslint-disable jsx-a11y/no-autofocus */
import { z } from 'zod';
import volumeIcon from '../../assets/images/shareonweb/vollume_icon.svg';
import { Button } from '../renderer/theme/buttons/Button/Button';
import styles from './Password.module.scss';

const FormSchema = z.object({
  password: z.string(),
});

export interface PasswordProps {
  onPasswordSubmit: (password: string) => void;
}

export function Password({ onPasswordSubmit }: PasswordProps) {
  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        const formData = new FormData(
          event.target as unknown as HTMLFormElement
        );
        onPasswordSubmit(
          FormSchema.parse(Object.fromEntries(formData.entries())).password
        );
      }}
    >
      <img alt="Vollume Inc." height={60} src={volumeIcon} width={60} />
      <h1>Password protected</h1>
      <h2>Please enter the password for this link</h2>
      <h3>If you don’t know the password, please ask the link owner for it.</h3>
      <input autoFocus name="password" type="password" />
      <div className={styles.divider} />
      <Button size="XL" type="submit" variant="primary">
        Continue
      </Button>
    </form>
  );
}
