import { z } from 'zod';
import {
  FolderIdSchema,
  ProjectIdSchema,
  ResourceIdSchema,
  WorkspaceId,
  WorkspaceIdSchema,
} from '../../common/Opaques';
import { PermissionSchema } from '../../common/Permission';
import { transformToDate, transformToNull } from '../../common/zod-utils';

export const BackendFolderSchema = z
  .object({
    created_at: z.string().transform(transformToDate),
    folder_id: FolderIdSchema,
    name: z.string(),
    parent_folder_id: FolderIdSchema.nullish().transform(transformToNull),
    permission_list: z.array(PermissionSchema),
    project_id: ProjectIdSchema.nullish().transform(transformToNull),
    resource_id: ResourceIdSchema,
  })
  // .strict()
  .transform((prev) => ({
    createdAt: prev.created_at,
    docType: 'folder' as const,
    folderId: prev.folder_id,
    name: prev.name,
    parentFolderId: prev.parent_folder_id,
    permissionList: prev.permission_list,
    projectId: prev.project_id,
    resourceId: prev.resource_id,
  }));

export type Folder = z.infer<typeof BackendFolderSchema> & {
  workspaceId: WorkspaceId;
};

export const GetFolderListResponseSchema = z.object({
  records: BackendFolderSchema.array(),
  size: z.number(),
});

export const CreateFolderResponseSchema = BackendFolderSchema;

export type CreateFolderResponse = z.infer<typeof CreateFolderResponseSchema>;

export type GetFolderListResponse = z.infer<typeof GetFolderListResponseSchema>;

export function ParseFolder(value: unknown, workspaceId: WorkspaceId): Folder {
  return { ...BackendFolderSchema.parse(value), workspaceId };
}

export function ParseGetFolderListResponse(
  value: unknown,
  workspaceId: WorkspaceId
): Folder[] {
  return GetFolderListResponseSchema.parse(value).records.map((playlist) => ({
    ...playlist,
    workspaceId,
  }));
}

export const defaultFolder: Folder = {
  createdAt: new Date('2024-04-11T18:19:47.000Z'),
  docType: 'folder',
  folderId: FolderIdSchema.parse('folder1'),
  name: 'f1 t',
  parentFolderId: null,
  permissionList: [],
  projectId: ProjectIdSchema.parse('project1'),
  resourceId: ResourceIdSchema.parse('resource project1'),
  workspaceId: WorkspaceIdSchema.parse('workspace1'),
};
