import {
  DndContext,
  DragEndEvent,
  DropAnimation,
  Over,
  PointerSensor,
  defaultDropAnimation,
  pointerWithin,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import { noop } from 'lodash';
import { MutableRefObject, createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { P, match } from 'ts-pattern';
import { Song } from '../../common/Song';
import { transformEmptyArray } from '../../common/zod-utils';
import { assignSongsToPlaylist } from '../actions/songs/assignSongsToPlaylist';
import { moveResources } from '../actions/songs/moveResources';
import { Dispatch, useAppSelector } from '../reducers/types';
import { api } from '../services/api/api';
import { Project, Workspace } from '../services/api/types/Workspace';
import { selectedSongsSelector } from '../store/selectors';
import { Folder } from '../types/Folder';
import { Playlist } from '../types/Playlist';
import { isIntersectedBy } from './array-utils';
import styles from './dnd.module.scss';
import { HomePath, matchPossiblePathsNullable } from './route-utils';
import { selectedSongsWithIndexOrder } from './song-utils';

export enum LOCATION {
  SEARCH,
  LEFT_SIDE_BAR,
  SONG_LIST,
  PLAYER,
  PLAY_QUEUE,
}

export interface DraggingData {
  resource: Workspace | Playlist | Folder | Project | Song[];
  location: LOCATION;
}

export interface DraggingDataExtra extends DraggingData {
  from: HomePath;
}

export interface TypedOver<T> extends Omit<Over, 'data'> {
  data: MutableRefObject<T>;
}

export function isDraggingSongs(
  resource: DraggingData['resource'] | undefined
): resource is Song[] {
  return Array.isArray(resource);
}

export const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

interface AppDndContext {
  draggingData: DraggingDataExtra | undefined;
  setDraggingData: (data: DraggingDataExtra | undefined) => void;
}

const AppDnd = createContext({} as AppDndContext);

/**
 * draggingData exists as a patch to active, because the library will remove active.data when the element is unmounted, draggable element can unmount before the drag ends (e.g: virtualized lists)
 */
export function useAppDndContext() {
  return useContext(AppDnd);
}

// TODO: Selected context songs should be global
// TODO: Selected context should not contain index
// TODO: selected songs should be global instead of calculated multiples
export function DndProvider({ children }: { children: React.ReactNode }) {
  const dispatch: Dispatch = useDispatch();
  const [draggingData, setDraggingData] = useState<
    DraggingDataExtra | undefined
  >(undefined);

  const selected = useAppSelector((state) =>
    selectedSongsWithIndexOrder(selectedSongsSelector(state))
  );

  const location = useAppSelector((state) => state.router.location);

  const songs = useAppSelector((state) =>
    transformEmptyArray(
      state.workspace.selectedWorkspaceId &&
        api.endpoints.getWorkspaceSongs.select(
          state.workspace.selectedWorkspaceId
        )(state).data
    )
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  function onDragEnd({ over }: DragEndEvent) {
    document.body.style.setProperty('cursor', '');

    match([draggingData, over as TypedOver<DraggingData> | null])
      .with(
        [
          { resource: P.select('song', P.array()) },
          {
            data: {
              current: {
                resource: {
                  docType: 'playlist',
                  playlistId: P.select('playlistId'),
                },
                location: LOCATION.LEFT_SIDE_BAR,
              },
            },
          },
        ],
        ({ song, playlistId }) => {
          dispatch(
            assignSongsToPlaylist({
              audioIds: song.map((s) => s.audioId),
              index: 0,
              playlistId,
            })
          );
        }
      )
      .with(
        [
          {
            resource: { docType: P.union('playlist', 'folder') },
          },
          {
            data: {
              current: {
                resource: {
                  docType: P.union('project', 'folder', 'workspace'),
                },
                location: LOCATION.LEFT_SIDE_BAR,
              },
            },
          },
        ],
        ([source, target]) => {
          dispatch(
            moveResources({
              draggingResources: [source.resource],
              droppingResource: target.data.current.resource,
            })
          );
        }
      )
      .otherwise(noop);
    setDraggingData(undefined);
  }

  return (
    <DndContext
      autoScroll={false}
      collisionDetection={pointerWithin}
      onDragCancel={onDragEnd}
      onDragEnd={onDragEnd}
      onDragStart={({ active }) => {
        // to avoid subscribing to selected data in each SongListItem, we hydrate the selected songs here
        const current = active.data.current as DraggingData;
        const from = matchPossiblePathsNullable(location);

        if (!from) {
          throw new Error('from is undefined');
        }

        setDraggingData({
          ...current,
          from,
          resource:
            isDraggingSongs(current.resource) &&
            isIntersectedBy(
              selected,
              current.resource,
              (id, song) => id === song.audioId
            )
              ? selected.map((id) => songs.find((s) => s.audioId === id)!)
              : current.resource,
        });
        document.body.style.setProperty('cursor', 'grabbing');
      }}
      sensors={sensors}
    >
      <AppDnd.Provider
        value={{
          draggingData,
          setDraggingData,
        }}
      >
        {children}
      </AppDnd.Provider>
    </DndContext>
  );
}

// export function contains(rect: ClientRect, x: number, y: number) {
//   return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
// }

// /**
//  * leaving this here for historic purposes: calculating this way is not precise
//  * useDndMonitor.onDragMove does not change the over element when the pointer is outside of it, so this method in imprecise.
//  */
// export function isTopOrBottom(
//   threshold: number,
//   rect: ClientRect,
//   x: number,
//   y: number
// ): 'top' | 'bottom' | null {
//   const shift = rect.height * threshold;
//   if (contains({ ...rect, bottom: rect.top + shift }, x, y)) {
//     return 'top';
//   }
//   if (contains({ ...rect, top: rect.bottom - shift }, x, y)) {
//     return 'bottom';
//   }
//   return null;
// }

export interface DropData {
  index?: number;
  location: LOCATION;
}

export interface DropLineProps {
  disabled?: boolean;
  variant: 'top' | 'bottom';
  isLast?: boolean;
  index: number;
  location: LOCATION;
}

export function DropLine({
  disabled,
  index,
  variant,
  isLast,
  location,
}: DropLineProps) {
  const { draggingData } = useAppDndContext();
  const disabled1 = disabled || !isDraggingSongs(draggingData?.resource);

  const id = `${location} ${variant} ${index}`;
  const { setNodeRef, over } = useDroppable({
    id,
    data: {
      index,
      location,
    } satisfies DropData,
    disabled: disabled1,
  });

  const isOver = over?.id === id || (isLast && over?.id === location);

  return (
    !disabled1 && (
      <>
        <div
          className={clsx(
            variant === 'top' ? styles.dropLineTop : styles.dropLineBottom,
            isOver && styles.isOver
          )}
          style={{
            left: location === LOCATION.SONG_LIST ? 20 : undefined,
            right: location === LOCATION.SONG_LIST ? 20 : undefined,
          }}
        />
        <div
          ref={setNodeRef}
          className={
            variant === 'top'
              ? styles.dropLineSensorTop
              : styles.dropLineSensorBottom
          }
        />
      </>
    )
  );
}

export interface DropBackgroundProps {
  disabled?: boolean;
  top: number;
  lastIndex?: number;
  location: LOCATION;
}

export function DropBackground({
  disabled,
  top,
  lastIndex,
  location,
}: DropBackgroundProps) {
  const { draggingData } = useAppDndContext();
  const disabled1 = disabled || !isDraggingSongs(draggingData?.resource);
  const { setNodeRef } = useDroppable({
    id: location,
    data: {
      index: lastIndex,
      location,
    } satisfies DropData,
    disabled: disabled1,
  });

  return (
    !disabled1 && (
      <>
        <div
          ref={setNodeRef}
          className={styles.dropLineSensorBackground}
          style={{
            top,
          }}
        />
      </>
    )
  );
}
