import { z } from 'zod';
import { transformToNull } from '../../../../common/zod-utils';

export const LyricsSchema = z
  .object({
    created_at: z.string().transform((date) => new Date(date)),
    updated_at: z.string().nullish().transform(transformToNull),
    lyrics: z.string().nullable(),
  })
  .transform((prev) => ({
    createdAt: prev.created_at,
    updatedAt: prev.updated_at,
    lyrics: prev.lyrics,
    docType: 'lyrics' as const,
  }));

export type Lyrics = z.infer<typeof LyricsSchema>;
