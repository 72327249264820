/**
 * @description window.requestAnimationFrame with setInterval interface
 *
 * From {@link https://css-tricks.com/snippets/javascript/replacements-setinterval-using-requestanimationframe/}
 * And from {@link http://codereview.stackexchange.com/questions/47889/alternative-to-setinterval-and-settimeout}
 */
export function requestAnimationFrameInterval(
  callback: () => void,
  delay: number
) {
  let start = Date.now();
  let stop = false;
  let handle: number;
  function intervalFunc() {
    const newNow = Date.now();
    if (newNow - start > delay) {
      start = newNow;
      callback();
    }
    if (!stop) {
      handle = window.requestAnimationFrame(intervalFunc);
    }
  }
  handle = window.requestAnimationFrame(intervalFunc);
  return () => {
    cancelAnimationFrame(handle);
    stop = true;
  };
}
