import { z } from 'zod';

export type RemoveAction = 'remove' | 'cancel' | 'leave';

// export type PlaylistRole =
//   | 'audience'
//   | 'collaborator'
//   | 'editor'
//   | 'playlist_owner';

export const PlaylistRoleSchema = z.enum([
  'audience',
  'collaborator',
  'editor',
  'playlist_owner',
  'playlist_viewer',
]);

export type PlaylistRole = z.infer<typeof PlaylistRoleSchema>;

// export type PlaylistGuestRole =
//   | 'playlist_guest_editor'
//   | 'playlist_guest_viewer';

export const PlaylistGuestRoleSchema = z.enum([
  'playlist_guest_editor',
  'playlist_guest_viewer',
]);

export type PlaylistGuestRole = z.infer<typeof PlaylistGuestRoleSchema>;

// export type Role = PlaylistRole | PlaylistGuestRole;

export const RoleSchema = z.union([
  PlaylistRoleSchema,
  PlaylistGuestRoleSchema,
]);

export type Role = z.infer<typeof RoleSchema>;

export type RoleOrRemoveAction = Role | RemoveAction;

export type Permission =
  | 'playlist_view'
  | 'playlist_create'
  | 'playlist_add_song'
  | 'playlist_add_song_request'
  | 'playlist_remove_song'
  | 'playlist_remove_own_song'
  | 'playlist_edit'
  | 'playlist_invite_collaborator'
  | 'playlist_remove_collaborator'
  | 'playlist_remove_invite'
  | 'playlist_change_collaborator_role'
  | 'playlist_change_invitation_role'
  | 'playlist_generate_link'
  | 'playlist_delete'
  | 'playlist_change_artwork'
  | 'playlist_accept_invitation'
  | 'playlist_decline_invitation'
  | 'audio_edit_metadata'
  | 'audio_edit_artwork'
  | 'audio_delete'
  | 'audio_post_comment'
  | 'audio_upload';

// export interface PermissionMapping {
//   playlist_owner: Permission[];
//   collaborator: Permission[];
//   editor: Permission[];
//   audience: Permission[];
//   playlist_guest_editor: Permission[];
//   playlist_guest_viewer: Permission[];
// }

export type PermissionMapping = Record<Role, Permission[]>;
