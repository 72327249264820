import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { P, match } from 'ts-pattern';
import playlistPlaceholder3 from '../../assets/images/playlist_placeholder3.svg';
import Artwork from '../renderer/components/Artwork';
import { API_HOST } from '../renderer/services/apiHost';
import { Button } from '../renderer/theme/buttons/Button/Button';
import { MaskIcon } from '../renderer/theme/data-display/MaskIcon/MaskIcon';
import styles from './TrackList.module.scss';
import { selectAudio, useAppDispatch, useAppSelector } from './api';
import { Audio, SharedLink } from './types';

function stopPropagation(e: React.MouseEvent) {
  e.stopPropagation();
}

/**
 * for upsell purposes
 */
function placeholderNavigation(e: React.MouseEvent) {
  e.stopPropagation();
  e.preventDefault();
  window.location.href = 'https://account.vollume.com/';
}

export interface TrackListProps {
  shareId: string;
  sharedLink: SharedLink;
}

export function TrackList({ sharedLink, shareId }: TrackListProps) {
  const playing = useAppSelector((state) => state.player.selectedAudio);
  const isPlaying = useAppSelector((state) => state.player.isPlaying);
  const [isHover, setIsHover] = useState(-1);
  const dispatch = useAppDispatch();

  const play = useCallback(
    (audio: Audio) => {
      dispatch(selectAudio(audio));
    },
    [dispatch]
  );

  return (
    <div className={styles.trackList}>
      <div className={styles.trackListHeader}>
        <div className={styles.artwork}>
          {sharedLink.playlist ? (
            <img
              alt="Artwork"
              height={75}
              src={
                sharedLink.cover_image_path ??
                sharedLink.playlist?.cover_image_path ??
                playlistPlaceholder3
              }
              width={75}
            />
          ) : (
            <Artwork
              coverImagePath={sharedLink.cover_image_path}
              id={sharedLink.audio_list[0]?.audio_id ?? ''}
              placeholder="file"
              size={75}
              title={sharedLink.audio_list[0]?.title ?? ''}
            />
          )}
          <div className={styles.artworkText}>
            <span className={styles.kind}>
              {sharedLink.playlist ? 'Playlist' : 'Track'}
            </span>
            <span className={styles.title}>
              {sharedLink.playlist?.title ??
                sharedLink.audio_list[0]?.title ??
                ''}
            </span>
          </div>
        </div>
        {(sharedLink.playlist?.description?.length ?? 0) > 0 && (
          <span className={styles.subtitle}>
            {sharedLink.playlist?.description}
          </span>
        )}
        {sharedLink.playlist && !sharedLink.disabled_download && (
          <form
            action={`${API_HOST}share/${shareId}/download/all`}
            className={styles.download}
            method="POST"
          >
            <input name="password" type="hidden" value={sharedLink.password} />
            <input name="player_type" type="hidden" value="playlist" />
            <Button type="submit" variant="secondary">
              Download All
            </Button>
          </form>
        )}
        <div className={styles.divider} />
      </div>
      {sharedLink.audio_list.length > 0 ? (
        <ul>
          {sharedLink.audio_list.map((audio, index) => {
            const isMounted = playing?.audio_id === audio.audio_id;
            return (
              <li
                key={audio.audio_id}
                className={clsx(isMounted && styles.selected)}
              >
                <button
                  onClick={() => play(audio)}
                  onMouseEnter={() => setIsHover(index)}
                  onMouseLeave={() => setIsHover(-1)}
                  type="button"
                >
                  {match([isMounted, isPlaying, isHover])
                    .with([true, true, P._], () => <MaskIcon variant="pause" />)
                    .with(P.union([true, P._, P._], [P._, P._, index]), () => (
                      <MaskIcon variant="play" />
                    ))
                    .otherwise(() => (
                      <span className={styles.index}>{index + 1}</span>
                    ))}
                  <div className={styles.titles}>
                    <div>
                      <span className={styles.title}>{audio.title}</span>
                    </div>
                    {(audio.metadata_artist?.length ?? 0) > 0 && (
                      <div>
                        <span className={styles.artist}>
                          {audio.metadata_artist}
                        </span>
                      </div>
                    )}
                  </div>
                </button>
                {!sharedLink.disabled_download && (
                  <form
                    action={`${API_HOST}share/${shareId}/download/audio/${audio.audio_id}`}
                    className={styles.download}
                    method="POST"
                  >
                    <input
                      name="password"
                      type="hidden"
                      value={sharedLink.password}
                    />
                    <input
                      name="player_type"
                      type="hidden"
                      value={sharedLink.playlist ? 'playlist' : 'audio'}
                    />
                    <button onClick={stopPropagation} type="submit">
                      <MaskIcon size={18} variant="download" />
                    </button>
                    <button onClick={placeholderNavigation} type="button">
                      <MaskIcon size={18} variant="starStarred" />
                    </button>
                    <button onClick={placeholderNavigation} type="button">
                      <MaskIcon size={18} variant="compose" />
                    </button>
                    <button onClick={placeholderNavigation} type="button">
                      <MaskIcon size={18} variant="dots" />
                    </button>
                  </form>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <div className={styles.empty}>This playlist is empty</div>
      )}
    </div>
  );
}

export interface TopCardProps {
  shareId: string;
  sharedLink: SharedLink;
}

export function TopCard({ sharedLink, shareId }: TopCardProps) {
  return (
    <div className={styles.topCardWrapper}>
      <div className={styles.topCard}>
        <TrackList sharedLink={sharedLink} shareId={shareId} />
      </div>
    </div>
  );
}

export interface AudioListProps {
  shareId: string;
  sharedLink: SharedLink;
}

export function AudioList({ sharedLink, shareId }: AudioListProps) {
  return (
    <div className={styles.player}>
      <TopCard sharedLink={sharedLink} shareId={shareId} />
    </div>
  );
}
