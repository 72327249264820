import { z } from 'zod';
import { CollaboratorSchema } from '../../../types/Collaborator';

export const LoggingErrorSchema = z
  .object({
    code: z.enum(['INCORRECT_PASSWORD']),
    message: z.string(),
  })
  .strict();

export type LoggingError = z.infer<typeof LoggingErrorSchema>;

export const SignUpSchema = z.object({
  token: z.string(),
  user: CollaboratorSchema,
});

export type SignUpResponse = z.infer<typeof SignUpSchema>;

export const AccountManageTokenSchema = z.object({
  scope: z.literal('manage_account'),
  token: z.string(),
  url: z.string(),
});

export type AccountManageToken = z.infer<typeof AccountManageTokenSchema>;
