import { z } from 'zod';
import {
  AttachmentFolderIdSchema,
  AttachmentIdSchema,
  SongIdSchema,
} from '../../../../common/Opaques';

export const AttachmentSchema = z
  .object({
    string_id: AttachmentIdSchema,
    file_name: z.string(),
    path: z.string(),
    size: z.number(),
    created_at: z.string().transform((date) => new Date(date)),
    deleted_at: z
      .string()
      .transform((date) => new Date(date))
      .nullish(),
    audio_id: SongIdSchema.optional(),
  })
  .transform((prev) => ({
    stringId: prev.string_id,
    fileName: prev.file_name,
    path: prev.path,
    size: prev.size,
    createdAt: prev.created_at,
    deletedAt: prev.deleted_at,
    audioId: prev.audio_id,
  }));

export type Attachment = z.infer<typeof AttachmentSchema>;

export const GetSongAttachmentListResponseSchema = z.object({
  records: z.array(AttachmentSchema),
  size: z.number(),
});

export type GetSongAttachmentListResponse = z.infer<
  typeof GetSongAttachmentListResponseSchema
>;

export const AttachmentFolderSchema = z
  .object({
    string_id: AttachmentFolderIdSchema,
    name: z.string(),
    attachment_ids: AttachmentIdSchema.array(),
    created_at: z.string().transform((date) => new Date(date)),
    deleted_at: z
      .string()
      .transform((date) => new Date(date))
      .nullish(),
  })
  .transform((prev) => ({
    stringId: prev.string_id,
    name: prev.name,
    attachmentIds: prev.attachment_ids,
    createdAt: prev.created_at,
    deletedAt: prev.deleted_at,
  }));

export type AttachmentFolder = z.infer<typeof AttachmentFolderSchema>;

export const GetSongAttachmentFolderListResponseSchema = z.object({
  records: z.array(AttachmentFolderSchema),
  size: z.number(),
});

export type GetSongAttachmentFolderListResponse = z.infer<
  typeof GetSongAttachmentFolderListResponseSchema
>;
