import logo from '../../assets/images/shareonweb/vollume.svg';
import { Button } from '../renderer/theme/buttons/Button/Button';
import styles from './TopBar.module.scss';

export function TopBar() {
  return (
    <form action="https://account.vollume.com/" className={styles.root}>
      <img alt="Vollume Inc." height={30} src={logo} width={127} />
      <Button size="L" type="submit" variant="tertiary">
        Sign In
      </Button>
      <Button size="L" type="submit" variant="primary">
        Sign Up
      </Button>
    </form>
  );
}
