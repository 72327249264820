import { z } from 'zod';
import { transformToDate } from '../../../../common/zod-utils';

export const WorkspaceRoleSchema = z
  .object({
    workspace_id: z.number(),
    role_id: z.string(),
    role_name: z.string(),
    role_level: z.number(),
    override_level: z.number(),
    created_at: z.string().transform(transformToDate),
    is_system_created: z.number(),
  })
  .transform((prev) => ({
    workspaceId: prev.workspace_id,
    roleId: prev.role_id,
    roleName: prev.role_name,
    roleLevel: prev.role_level,
    overrideLevel: prev.override_level,
    createdAt: prev.created_at,
    isSystemCreated: prev.is_system_created,
  }));

export type WorkspaceRole = z.infer<typeof WorkspaceRoleSchema>;

export const GetWorkspaceRoleListResponseSchema = z.object({
  records: z.array(WorkspaceRoleSchema),
  size: z.number(),
});

export type GetWorkspaceRoleListResponse = z.infer<
  typeof GetWorkspaceRoleListResponseSchema
>;
