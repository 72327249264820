import { z } from 'zod';
import { CommentIdSchema, SongIdSchema } from '../../common/Opaques';
import { transformToDate } from '../../common/zod-utils';

export const ControlCommentSchema = z
  .object({
    comment_id: CommentIdSchema,
    user_id: z.string(),
    audio_id: SongIdSchema,
    text: z.string(),
    timestamp: z.number().nullish(),
    created_at: z.string().transform(transformToDate),
    deleted_at: z.string().transform(transformToDate).nullish(),
    name: z.string(),
    avatar_url: z.string(),
    mentions: z.unknown(),
  })
  .strict()
  .transform(
    (prev) =>
      ({
        commentId: prev.comment_id,
        userId: prev.user_id,
        audioId: prev.audio_id,
        text: prev.text,
        timestamp: prev.timestamp,
        createdAt: prev.created_at,
        name: prev.name,
        avatarUrl: prev.avatar_url,
        mentions: prev.mentions,
      } as const)
  );

export type ControlComment = z.infer<typeof ControlCommentSchema>;

export const GetControlCommentListResponseSchema = z.object({
  records: ControlCommentSchema.array(),
  size: z.number(),
});

export type GetControlCommentListResponse = z.infer<
  typeof GetControlCommentListResponseSchema
>;

export const defaultControlComment: ControlComment = {
  commentId: CommentIdSchema.parse('1'),
  userId: '1',
  audioId: SongIdSchema.parse('1'),
  text: 'Hello World',
  timestamp: null,
  createdAt: new Date('2021-01-01T00:00:00Z'),
  name: 'John Doe',
  avatarUrl: 'https://example.com',
  mentions: [],
};
