import { PlaylistId, SongId } from '../../../common/Opaques';
import { createAppAsyncThunk } from '../../reducers/types';
import { api } from '../../services/api/api';
import { findPlaylistInSelectedWorkspaceSelector } from '../../store/selectors';
import { tryCatchFinallyReport } from '../../utils/error-utils';
import { atLeastOneSongWasAdded } from '../../utils/playlist-utils';
import { mkPlaylistPath } from '../../utils/route-utils';
import { enqueueSnackbar } from '../view';
import { openDialog } from '../view/openDialog';

export const assignSongsToPlaylist = createAppAsyncThunk<
  void,
  {
    audioIds: SongId[];
    playlistId: PlaylistId;
    index: number;
  }
>(
  'assignSongsToPlaylist',
  async ({ audioIds, playlistId, index }, { getState, dispatch }) => {
    return tryCatchFinallyReport('assignSongsToPlaylist', async () => {
      const state = getState();
      const {
        settings: { duplicatedAssignSongAlertHidden },
        workspace: { selectedWorkspaceId },
      } = state;

      if (!selectedWorkspaceId) {
        throw new Error('No workspace selected');
      }

      const oldPlaylist =
        findPlaylistInSelectedWorkspaceSelector(playlistId)(state);

      const availableAudios = audioIds.filter(
        (audioId) => !oldPlaylist?.songIds.some((one) => one === audioId)
      );
      if (
        availableAudios.length !== audioIds.length &&
        !duplicatedAssignSongAlertHidden
      ) {
        let message = `Some of these are already in your ${oldPlaylist?.title} playlist. Duplicates will be ignored.`;
        if (audioIds.length === 1) {
          message = `This song is already in your ${oldPlaylist?.title} playlist. Duplicates will be ignored.`;
        } else if (availableAudios.length === 0) {
          message = `These are already in your ${oldPlaylist?.title} playlist. Duplicates will be ignored.`;
        }

        dispatch(
          openDialog({
            type: 'duplicated song',
            message,
            title: 'Already added',
          })
        );
      }
      if (availableAudios.length > 0) {
        await dispatch(
          api.endpoints.assignSongsToPlaylist.initiate({
            workspaceId: selectedWorkspaceId,
            audioIds: availableAudios,
            playlistId,
            index,
          })
        );

        const newPlaylist = findPlaylistInSelectedWorkspaceSelector(playlistId)(
          getState()
        );

        if (
          newPlaylist &&
          oldPlaylist &&
          atLeastOneSongWasAdded(oldPlaylist, newPlaylist, availableAudios)
        ) {
          dispatch(
            enqueueSnackbar(
              `Added to ${newPlaylist.title}`,
              undefined,
              mkPlaylistPath(
                newPlaylist.playlistId,
                newPlaylist.workspaceId,
                newPlaylist.projectId
              )
            )
          );
        }
      }
    });
  }
);
