import { z } from 'zod';
import {
  fromNullish,
  transformToDate,
  transformToNull,
  transformTrim,
} from '../../common/zod-utils';

// export interface Collaborator {
//   userId: string;
//   avatarUrl: string | null;
//   email: string;
//   name: string;
//   artistName: string;
//   occupation: string | null;
//   location: string | null;
//   createdAt: Date;
// }

export const CollaboratorSchema = z
  .object({
    artist_name: z
      .string()
      .transform(transformTrim)
      .nullish()
      .transform(fromNullish('')),
    avatar_url: z.string().nullish().transform(transformToNull),
    created_at: z.string().transform(transformToDate),
    email: z.string(),
    location: z
      .string()
      .transform(transformTrim)
      .nullish()
      .transform(transformToNull),
    name: z
      .string()
      .transform(transformTrim)
      .nullish()
      .transform(fromNullish('')),
    occupation: z
      .string()
      .transform(transformTrim)
      .nullish()
      .transform(transformToNull),
    user_id: z.string(),
  })
  .transform((prev) => ({
    artistName: prev.artist_name,
    avatarUrl: prev.avatar_url,
    createdAt: prev.created_at,
    email: prev.email,
    location: prev.location,
    name: prev.name,
    occupation: prev.occupation,
    userId: prev.user_id,
  }));

export type Collaborator = z.infer<typeof CollaboratorSchema>;

export const defaultCollaborator: Collaborator = {
  artistName: '',
  avatarUrl: 'https://i.pravatar.cc/150?img=2',
  createdAt: new Date('2021-01-01T00:00:00Z'),
  email: '',
  location: null,
  name: '',
  occupation: null,
  userId: '1',
};
