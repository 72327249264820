import volumeIcon from '../../assets/images/shareonweb/vollume_icon.svg';
import styles from './Password.module.scss';

export function SomethingWentWrong() {
  return (
    <div className={styles.root}>
      <img alt="Vollume Inc." height={60} src={volumeIcon} width={60} />
      <h1>Oops, something went wrong</h1>
      <h2>We’re working on getting this fixed.</h2>
      <h3>
        The issue has been logged for investigation. Please try again later.
      </h3>
      <a href=".">Reload Page</a>
      <a href="https://vollume.com/">Back To Vollume</a>
    </div>
  );
}
