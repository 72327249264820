function isMac(): boolean {
  // return navigator.platform === 'MacIntel';
  return /Mac|iPod|iPhone|iPad/.test(navigator.platform);
}

export function isWindows(): boolean {
  // TODO: replace with getEnvironment
  // return window.nativeAPI.os.platform() === 'win32';
  return /Win32/.test(navigator.platform);
}

/**
 * {@link https://stackoverflow.com/a/13348618/9130688}
 */
export function isChrome(): boolean {
  // please note,
  // that IE11 now returns undefined again for window.chrome
  // and new Opera 30 outputs true for window.chrome
  // but needs to check if window.opr is not undefined
  // and new IE Edge outputs to true now for window.chrome
  // and if not iOS Chrome check
  // so use the below updated condition
  const isChromium = (window as any).chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof (window as any).opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edg') > -1;
  const isIOSChrome = Boolean(winNav.userAgent.match('CriOS'));

  return (
    isIOSChrome ||
    (isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false)
  );
}

export type OperatingSystem = 'macOS' | 'Windows' | 'Other';

export interface Environment {
  os: OperatingSystem;
  isElectron: boolean;
  isChrome: boolean;
}

function getOS(): OperatingSystem {
  if (isMac()) {
    return 'macOS';
  }
  if (isWindows()) {
    return 'Windows';
  }
  return 'Other';
}

export function getEnvironment(): Environment {
  return {
    isElectron: Boolean(window.nativeAPI.isElectron),
    os: getOS(),
    isChrome: isChrome(),
  };
}
